import React from "react";
import {FiEdit2, FiAirplay, FiCreditCard, FiGlobe, FiLayout,FiLifeBuoy} from "../assets/icons/vander"
export default function AiFeatures(){
    const featureData = [
        {
            icon:FiEdit2,
            title:'AI Generator',
            desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
        },
        {
            icon:FiAirplay,
            title:'Advanced Dashboard',
            desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
        },
        {
            icon:FiCreditCard,
            title:'Payment Gateways',
            desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
        },
        {
            icon:FiGlobe,
            title:'Multi-Lingual',
            desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
        },
        {
            icon:FiLayout,
            title:'Custom Templates',
            desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
        },
        {
            icon:FiLifeBuoy,
            title:'Support Platform',
            desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
        },
    ]
    return(
        <>
        <div className="container relative md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Buy $SONIC on Flooz</h3>
                    <p className="text-slate-400 max-w-xl mx-auto">Use Flooz for a secure and seamless transaction experience.</p>
                </div>

                <div className="grid grid-cols-1 pb-6 text-center">
                    <p align="center"><iframe width="400" height="720" frameborder="0" allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *" src="https://flooz.xyz/embed/trade?swapDisabled=false&swapNetwork=eth&swapToTokenAddress=0x3575a80720CfB119347872801d0bd1402E644766&swapLockToToken=true&onRampDisabled=false&onRampNetwork=eth&onRampAsDefault=false&onRampTokenAddress=0x3575a80720CfB119347872801d0bd1402E644766&onRampLockToken=true&network=eth&lightMode=false&backgroundColor=transparent&miniApp=false&miniappIntent=swap" ></iframe></p>
                </div>
            </div>
        </>
    )
}
