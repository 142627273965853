import React from "react";
import { Link } from "react-router-dom";

import aboutImg from "../assets/images/features/2.png"

import {FiCheckCircle, MdKeyboardArrowRight} from '../assets/icons/vander'

export default function AboutTwo(){
    return(
        <>
            <div className="container relative md:mt-24 mt-16">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
                    <div className="relative order-1 md:order-2">
                        <div className="" style={{float: 'right'}}>
                            <img src="/robot-sonic.png" width="400" height="400" alt=""/>
                        </div>
                    </div>

                    <div className="order-2 md:order-1">
                        <h4 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">About $SONIC</h4>
                        <p className="text-slate-400">Building the best memeable community together with OpenAI's SearchGPT, internally code-named Sonic.</p>
                        <br />
                        <p className="text-slate-400">The tokenomics is simple:</p>
                        <ul className="list-none text-slate-400 mt-4">
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2"/> 6.9B Supply</li>
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2"/> No Tax</li>
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2"/> LP Locked</li>
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2"/> No Presale</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
