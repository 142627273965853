import React,{useEffect} from "react";
import { Link } from "react-router-dom";

import Navbar from "../components/navbar";
import BrandLogo from "../components/brandLogo";
import AboutThree from "../components/aboutThree";
import Features from "../components/features";
import AboutOne from "../components/aboutOne";
import AboutTwo from "../components/aboutTwo";
import AiFeatures from "../components/aiFeatures";
import Pricing from "../components/pricing";
import Clients from "../components/clients";
import Blogs from "../components/blogs";
import Tweets from "../components/tweets";
import Footer from "../components/footer";
import Switcher from "../components/switcher";
import {FiFileText, FiVideo, FiPauseCircle, FiCamera, FiLayout,FiMessageCircle, FiHexagon} from '../assets/icons/vander'

export default function IndexThree(){
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
      }, []);
        const casesData = [
        {
            icon:FiFileText,
            title:'1B SUPPLY',
            desc:'The phrasal sequence of the is now so that many campaign and benefit'
        },
        {
            icon:FiVideo,
            title:'NO TAX',
            desc:'The phrasal sequence of the is now so that many campaign and benefit'
        },
        {
            icon:FiPauseCircle,
            title:'LP BURNED',
            desc:'The phrasal sequence of the is now so that many campaign and benefit'
        },
    ]
    return(
        <>
        <Navbar/>
        <section className="relative md:py-48 py-36 items-center mt-[74px] md:mx-4 md:rounded-3xl overflow-hidden">
            <div className="absolute top-0 start-0 w-full h-full z-0 pointer-events-none overflow-hidden" style={{opacity: .5}}>
                <iframe src="https://www.youtube.com/embed/zx3UZNwZijs?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1" title="my-fram" className="absolute top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 w-screen h-[56.25vw] min-h-screen md:min-w-[177.77vw] min-w-[400vw]"></iframe>
            </div>
            <div className="absolute inset-0 bg-gradient-to-t from-slate-950 via-slate-950/50 to-transparent"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 text-center">
                    <div className="">
                        <h4 className="font-bold lg:leading-normal leading-normal text-white text-4xl lg:text-7xl mb-5">OpenAI's challenge<br/>  <span className="bg-gradient-to-br from-amber-400 to-fuchsia-600 text-transparent bg-clip-text"> to Google.</span> </h4>
                        <p className="text-white/70 text-lg max-w-xl mx-auto">$SONIC is a community of AI enthusiasts and memelords who want to push the boundaries of OpenAI's new search engine technology.</p>

                        <div className="mt-6">
                            <Link target="_blank" to="https://app.uniswap.org/swap?exactField=output&inputCurrency=ETH&outputCurrency=0x3575a80720CfB119347872801d0bd1402E644766" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center text-white rounded-lg">BUY $SONIC</Link>
                            <p style={{paddingTop: 30}}>CA: 0x3575a80720CfB119347872801d0bd1402E644766</p>
                        </div>
                        <div className="mt-6">
                            <p style={{paddingTop: 30}}><Link target="_blank" to="https://www.dextools.io/app/en/ether/pair-explorer/0x12f0c96f86880d0720a6ac2c6eb3eb6cd4116433?t=1716259685812">DexTools</Link> | <Link target="_blank" to="https://dexscreener.com/ethereum/0x12f0c96f86880d0720a6ac2c6eb3eb6cd4116433">DexScreener</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="relative md:pb-24 pb-16">
            <AboutTwo/>
            <AboutThree/>
            <AiFeatures/>
        </section>
        <Footer/>
        </>
    )
}